// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import AdMovementsItems from '@/components/elements/place/AdMovementsItems'
// Filters
import { formatPrice, formatDate, formatNumber } from '@/filters'
// Services
import { getAdvertisingLinesByPlaceId } from '@/services/place'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'AdsMovements',
  filters: { formatPrice },
  mixins: [uiMixin],
  components: {
    CardContainer,
    CardContainerElement,
    VuetifyDataTable,
    AdMovementsItems
  },
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Concepto',
          value: 'subject'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Visitas',
          value: 'sessions'
        },
        {
          text: 'Cantidad',
          value: 'amount'
        }
      ],
      // Others
      movements: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos todos los datos necesarios para el componente
     */
    async getEveryNeededData() {
      // Loading
      this.processingRequest = true
      try {
        // Establecemos movimientos
        await this.setMovements()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos todos los movimientos (beneficios) del establecimiento
     * en publicidad
     */
    async setMovements() {
      const movements = await getAdvertisingLinesByPlaceId(this.placeData.id)
      this.movements = movements.map((movement) => {
        return {
          subject: `Publicidad ${formatDate(movement.dateFrom, 'MMMM YYYY')}`,
          date: formatDate(movement.dateFrom, 'MM/YYYY'),
          amount: formatPrice(movement.amount, 'EUR', true),
          sessions: formatNumber(movement.sessions)
        }
      })
    }
  }
}
